<template>
    <div class="activity-container">
        <van-dropdown-menu active-color="#B72E32">
            <van-dropdown-item
                v-model="classifyVal"
                :options="classify"
                @change="(v) => handleActivity({ belongClassification: v })"
            />
            <van-dropdown-item
                v-model="streetVal"
                :options="street"
                @change="(v) => handleActivity({ belongTown: v })"
            />
            <van-dropdown-item
                v-model="sortVal"
                :options="sort"
                @change="(v) => handleActivity({ orderLine: v })"
            />
        </van-dropdown-menu>
        <div class="activity-content-list">
            <make-card class="u-m-t-30" :list="activityList"></make-card>
        </div>
    </div>
</template>

<script>
import makeCard from "./components/make-card.vue";
import { getActivityList } from "@/service";
export default {
    components: {
        makeCard,
    },
    data() {
        return {
            classifyVal: "",
            streetVal: "",
            sortVal: "",
            classify: [
                { text: "全部分类", value: "" },
                { text: "演出", value: "1" },
                { text: "展览", value: "2" },
                { text: "讲座", value: "3" },
                { text: "培训", value: "4" },
                { text: "赛事", value: "5" },
                { text: "其他", value: "6" },
            ],
            street: [
                { text: "所属街道", value: "" },
                { text: "南京东路街道", value: "南京东路街道" },
                { text: "外滩街道", value: "外滩街道" },
                { text: "瑞金二路街道", value: "瑞金二路街道" },
                { text: "淮海中路街道", value: "淮海中路街道" },
                { text: "豫园街道", value: "豫园街道" },
                { text: "打浦桥街道", value: "打浦桥街道" },
                { text: "老西门街道", value: "老西门街道" },
                { text: "小东门街道", value: "小东门街道" },
                { text: "五里桥街道", value: "五里桥街道" },
                { text: "半淞园路街道", value: "半淞园路街道" },
                { text: "其他", value: "其他" },
            ],
            sort: [
                { text: "智能排序", value: "" },
                { text: "预约状态", value: "3" },
                { text: "默认开始时间", value: "1" },
            ],
            activityList: [],
        };
    },
    mounted() {
        this.getActivityList();
    },
    methods: {
        async getActivityList(options) {
            const params = {
                pageNo: 1,
                pageSize: 1000,
                ...options,
            };
            const { data } = await getActivityList(params);
            this.activityList = data.list;
        },
        handleActivity(options) {
            this.getActivityList(options);
        },
    },
};
</script>

<style lang="scss" scoped>
.activity-container {
    padding-bottom: 80px;
}
</style>
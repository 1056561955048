<template>
    <nav class="make-card">
        <div class="item-container" v-if="list.length">
            <div v-for="(item, index) in list" :key="index">
                <router-link
                    :to="{ path: '/activity/details', query: { id: item.id } }"
                >
                    <div class="item-card">
                        <van-image
                            :src="item.logoUrl"
                            width="164"
                            height="120"
                            fit="cover"
                            class="make-img"
                            radius="0 10"
                        />
                        <div class="make-content">
                            <figure>
                                <div class="make-title">
                                    {{ item.activityTitle }}
                                </div>
                                <slot>
                                    <div class="make-tag">
                                        {{ actType(item.actType) }}
                                    </div>
                                    <figcaption>
                                        <div class="card-address">
                                            {{ item.actAddress }}
                                        </div>
                                        <div class="u-m-t-20">
                                            {{ item.activityHour }}
                                        </div>
                                    </figcaption>
                                </slot>
                            </figure>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "make-card",
    props: ["list", "imgBaseUrl"],
    components: {},
    computed: {
        actType() {
            return function (type) {
                switch (type) {
                    case "1":
                        return "演出";
                    case "2":
                        return "展览";
                    case "3":
                        return "讲座";
                    case "4":
                        return "培训";
                    case "5":
                        return "赛事";
                    case "6":
                        return "其他";
                }
            };
        },
    },
    data() {
        return {};
    },
    methods: {
        getCategoryId() {},
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/_varibles";
@import "@/style/mixin";

.make-card {
    background-color: #fff;
    .item-container {
        @include wh(100%, auto);
        padding: 0 36px;
        .make-img {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }
        .item-card {
            @include fj(center);
            width: 100%;
            text-align: center;
            margin-top: 30px;
            border-radius: 20px;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.17);
            img {
                flex: 1;
                @include wh(100%, 234px);
                border-radius: 20px 0 0 20px;
            }
            .card-address {
                white-space: normal;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                /*! autoprefixer: off */
                -webkit-box-orient: vertical;
                /* autoprefixer: on */
                -webkit-line-clamp: 1;
                line-clamp: 1;
                word-break: break-all; /*追加这一行代码*/
            }
            .make-content {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: left;
                padding-left: 20px;
                figure {
                    width: 100%;
                    .make-title {
                        @include sc(24px, $--color-text-regular);
                        white-space: normal;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        /*! autoprefixer: off */
                        -webkit-box-orient: vertical;
                        /* autoprefixer: on */
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                        word-break: break-all; /*追加这一行代码*/
                    }
                    .make-tag {
                        @include sc(16px, $--color-text-primary);
                        border: 2px solid $--color-text-primary;
                        padding: 4px 16px;
                        display: inline-block;
                        border-radius: 28px;
                        line-height: 24px;
                    }
                    figcaption {
                        @include sc(20px, $--color-text-secondary);
                        overflow-wrap: break-word;
                        white-space: nowrap;
                        line-height: 28px;
                        padding: 12px 12px 0 0;
                        white-space: normal;
                        text-align: left;
                    }
                }
            }
        }
    }
    .clock-info {
        @include sc(24px, $--color-text-secondary);
        .clock-button {
            @include wh(130px, 50px);
            @include sc(22px, $--color-text-primary);
            border-radius: 50px;
            text-align: center;
            line-height: 50px;
            border: 1px solid $--color-text-primary;
            background: #f5e3e4;
            margin-left: 30px;
        }
    }
}
</style>